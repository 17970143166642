import React from 'react';
import { plans } from '../../../utils/dummyData';
import { Button } from '@deposits/ui-kit-react';
import { formatTime } from '../../../screens/dashboard/session/BookNew';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { isRefundEligible } from '../../../helpers/functions';

export const slotsCreator = (start, end, available) => {
  const slot = [...Array(end - start)].map((item, index) => {
    const hour = start + index;

    return {
      id: index,
      formattedTime: formatTime(hour),
      start: hour,
      available: available && available[hour] === 'not-booked',
    };
  });

  return slot;
};

export const intervalCreator = (start, end) => {
  return [...Array(end - start)].map((item, index) => index + 1);
};

const TimeCard = ({
  content,
  id,
  selected,
  setSelected,
  disabled,
  isAvailable,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={`
        w-full lg:w-[84px] h-9 flex items-center justify-center border border-gray-4  font-semibold text-xs rounded-lg
        ${
          disabled &&
          ' border-gray-4/30 text-renaissance-black/30 dark:text-renaissance-dark-black/30 '
        }
        ${
          selected === id &&
          isAvailable &&
          ' bg-primary-green/30 !text-primary-green !border-primary-green dark:!border-primary-dark-green dark:!text-primary-dark-green '
        }
        `}
      {...props}
    >
      {content}
    </button>
  );
};

const RescheduleTimePicker = ({
  available,
  availableLoading,
  session,
  selectedPlan,
  setSelectedPlan,
  setSelectedDate,
  selectedDate,
  selectedTime,
  setSelectedTime,
  selectedDuration,
  setSelectedDuration,
  submitHandler,
  coinBalance,
  isLoading,
}) => {
  const { startTime, endTime } = plans[selectedPlan - 1];
  const now = new Date();

  useEffect(() => {
    setSelectedTime(null);
  }, [selectedDate, setSelectedTime]);

  return (
    <div className="flex flex-col gap-6 lg:gap-12">
      <div className="">
        <p className="mb-4 font-medium text-base text-center lg:text-left">
          {' '}
          {selectedDate.toDateString()}{' '}
          <span className="font-semibold"> - Choose Time </span>
        </p>

        {availableLoading ? (
          <div className="text-sm font-semibold border border-black p-2 rounded-md">
            Loading Available time...{' '}
          </div>
        ) : (
          <div className=" grid grid-cols-1 lg:grid-cols-4 gap-y-2 lg:gap-y-6 w-full ">
            {slotsCreator(startTime, endTime, available).map((time) => {
              const timeString =
                new Date(selectedDate).toDateString() +
                ' ' +
                time.start +
                ':00:00';
              const timeDateFormat = new Date(timeString);
              const isValidTime = time.available && timeDateFormat > now;

              return (
                <TimeCard
                  key={time.id}
                  id={time.start}
                  isAvailable={time.available}
                  content={time.formattedTime}
                  selected={selectedTime}
                  setSelected={setSelectedTime}
                  disabled={!isValidTime}
                  onClick={() => {
                    isValidTime
                      ? setSelectedTime(time.start)
                      : toast.error('Please select a valid date and time');
                  }}
                />
              );
            })}
          </div>
        )}
      </div>

      <div className="flex justify-between items-baseline ">
        {!isRefundEligible(session?.data?.startTime, 8) &&
        session?.data?.payment === 'stripe' ? (
          <p className="flex items-baseline text-primary-gray">
            <span>Total Amount</span>
            <span className="self-start px-1 text-xs lg:text-base">£</span>
            <span className="font-bold font-droid text-[32px] lg:text-5xl text-renaissance-black dark:text-renaissance-dark-black">
              {session?.data?.amount / 2}
            </span>
          </p>
        ) : !isRefundEligible(session?.data?.startTime, 4) ? (
          <p className="flex items-baseline text-primary-gray">
            <span>Total Coin Amount</span>

            <span className="ml-2 font-bold font-droid text-4xl text-renaissance-black dark:text-renaissance-dark-black">
              {session?.data?.amount / 2} coin(s)
            </span>
          </p>
        ) : (
          ''
        )}
      </div>

      <div>
        <Button
          disabled={
            isLoading ||
            !selectedDuration ||
            !selectedPlan ||
            selectedTime === null
          }
          className={`!w-full !border-0 px-1 lg:!px-8 !text-primary-white 
                    ${
                      !selectedDuration ||
                      !selectedPlan ||
                      selectedTime === null
                        ? '!bg-gray-4'
                        : ' !bg-primary-green '
                    }`}
          size="xlarge"
          onClick={submitHandler}
        >
          {isLoading
            ? 'Processing..'
            : `Reschedule Session for ${selectedDate.toDateString()}`}
        </Button>
      </div>
    </div>
  );
};

export default RescheduleTimePicker;
